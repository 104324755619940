import React from "react"
import { Container } from "react-bootstrap"
import ArmChair from "../../../static/images/Armchair.svg"
import Box from "../../../static/images/Box.svg"
import Bulb from "../../../static/images/Buld-shield.svg"
import ColorProfile from "../../../static/images/Color-profile.svg"
import Position from "../../../static/images/Position.svg"
import Cta from "../../components/cta"
import Footer from "../../components/footer"
import GridContainer from "../../components/gridContainer"
import GridItem from "../../components/gridItem"
import Layout from "../../components/layout"
import LogoCloud from "../../components/LogoClouds"
import NavBar from "../../components/navBar"
import Seo from "../../components/seo"
import NewHero from "../../components/hero/newHero";

const Educator = ({ location }) => {
  return (
    <Layout>
      <Seo title="Team Coaches" />
      <NavBar location={location} />
      <Container>
        <NewHero
          title="Proudly support startup builders and innovators"
          description="Team Coaches helps consultants to create and run their startup and entrepreneurship focus community with the right tools"
        />
        <LogoCloud />
        <GridContainer>
          <GridItem
            icon={ColorProfile}
            width="62"
            height="62"
            title="Learn The Basics"
            description="Create curriculum that help build the knowledge needed to start and grow."
          />
          <GridItem
            icon={Box}
            width="62"
            height="62"
            title="Advanced Learning"
            description="Expand to specific workshop dedicated on accelerate grow."
          />
          <GridItem
            icon={Bulb}
            width="66"
            height="66"
            title="Help the Idea"
            description="Mentor and support startup and entrepreneurs in their journey."
          />
          <GridItem
            icon={Position}
            width="67"
            height="67"
            title="Create Goals"
            description="Measure what is important."
          />
          <GridItem
            icon={ArmChair}
            width="57"
            height="57"
            title="Take Decision"
            description="Help in the decision to make the next step."
          />
        </GridContainer>
        <Cta
          backgroundColor="back-blue"
          buttonColor="blue"
          title="Educate and mentor the future innovation"
          points={[
            "Create your own proven path in a platform that is build for you.",
          ]}
          buttonText="Let's Talk"
        />
        <Footer />
      </Container>
    </Layout>
  )
}

export default Educator
